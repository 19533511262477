export default class NoticeModel {
  constructor() {
    this.objRaw = {};
    this.id = '';
    this.status = 'HIDDEN';
    this.thumbnail = [];
    this.shareThumbnail = [];
    this.title = '';
    this.contents = '';
    this.createdAt = '';
    this.updatedAt = '';
  }
  setData(obj) {
    console.log('setData')
    if (obj) this.objRaw = obj;
    const {
      id,
      status,
      thumbnail,
      shareThumbnail,
      title,
      contents,
      createdAt,
      updatedAt,
    } = obj;
    this.id = id;
    this.status = status;
    if (thumbnail) {
      this.thumbnail = [
        {
          src: thumbnail,
        },
      ];
    }
    if (shareThumbnail) {
      this.shareThumbnail = [
        {
          src: shareThumbnail,
        },
      ];
    }
    this.title = title;
    this.contents = contents;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
  setStatusHidden(){
    this.status = 'HIDDEN';
  }
  getRegisterData() {
    let obj = {
      status: this.status,
      thumbnail: this.thumbnail.length > 0 ? this.thumbnail[0].src : '',
      shareThumbnail:
      this.shareThumbnail.length > 0
        ? this.shareThumbnail[0].src
        : '',
      title: this.title,
      contents: this.contents,
    };
    return obj;
  }
  getModifyData() {
    let obj = {
      status: this.status,
      title: this.title,
      thumbnail: this.thumbnail.length > 0 ? this.thumbnail[0].src : '',
      shareThumbnail:
      this.shareThumbnail.length > 0
        ? this.shareThumbnail[0].src
        : '',
      contents: this.contents,
    };
    return obj;
  }
}
